import React, { useEffect } from 'react'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

const updateUser = () => {
    const { id } = useParams();
    // console.log("customer id",id);
    
    const [updateUser, setUpdateUser] = useState({
      user_name:"",
      first_name : "",
      last_name : "",
      email_c :"",
      phone_mobile : "",
      password :"",
      confirmPassword :""
        
    })

    const navigate = useNavigate()
    const sessionId = useSelector((state) => state.session.sessionId);
    const user_id = useSelector((state) => state.session.userId.value);
    console.log(user_id);
    
    
    useEffect(() => {
      const fetchData = async () => {
        const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
        const method = "get_entry_list";
        const input_type = "JSON";
        const response_type = "JSON";
        const rest_data = JSON.stringify({
          session: sessionId,
          module_name: "Users",
          // query: "ref_refferals_cstm.user_id_c= 1",
          
          select_fields: [
            "id",
            "user_name",
            "first_name",
            "last_name",
            "email_c",
            "phone_mobile",
            
          ],
          query: id ? `users.id = '${id}'` : "",
        });
  
        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
          rest_data
        )}`;
  
        try {
            const response = await axios.get(fullUrl);
            console.log("API Response:", response.data);
            const entryList = response.data.entry_list;
            console.log(rest_data);
            
          
            // Extracting name_value_list from each entry in entry_list and storing them in the products array
            const products = entryList.map((entry) => entry.name_value_list);
            console.log("Products:", products); // Log to inspect the structure
          
            if (products.length > 0) {
              const userData = products[0]; // Directly get the first user data
              
              console.log("User Data:", userData); // Inspect the structure
          
              // Set the updateUser state with the relevant data
              setUpdateUser({
                user_name: userData.user_name.value || '',
                first_name: userData.first_name.value || '',
                last_name: userData.last_name.value || '',
                email_c: userData.email_c.value || '',
                phone_mobile: userData.phone_mobile.value || '',
                password: '',
                confirmPassword: '',
              });
            } else {
              console.warn("No entries found in entryList.");
            }
          } catch (error) {
          if (error.response) {
            console.error("An error occurred during get data:", error.response.data);
            console.error("Status code:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up request:", error.message);
          }
        }
      };
  
      if (sessionId) {
        fetchData();
      }
    }, [sessionId]);
    

    const handleUserChange = (e) => {
        const { name, value } = e.target;
        setUpdateUser((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

    const handleSubmit = async(e) =>{
        e.preventDefault();

        const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
        const method = "set_entry";
        const input_type = "JSON";
        const response_type = "JSON";
    
        const rest_data = JSON.stringify({
          session: sessionId,
          module_name: "Users",
          name_value_list: [
        { name: "id", value: id },
        { name: "user_name", value: updateUser.user_name },
        { name: "user_hash", value: updateUser.password },
        { name: "cnfrm_c", value: updateUser.cnfrm_c },
        { name: "first_name", value: updateUser.first_name },
        { name: "last_name", value: updateUser.last_name },
        { name: "email_c", value: updateUser.email_c },
        { name: "phone_mobile", value: updateUser.phone_mobile },
        
          ]
        });

        const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
          rest_data
        )}`;
    
        try {
          const response = await axios.put(fullUrl);
          console.log("API Response:", response.data);
          navigate("/user")
        } catch  (error) {
          if (error.response) {
            console.error("An error occurred during login:", error.response.data);
            console.error("Status code:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up request:", error.message);
          }
        }
    
    }
  return (
    <>
    <DashboardLayout>
       <DashboardNavbar />
       <MDBox py={3}>
        <div className='flex flex-col'>
       <form className="w-full flex flex-col justify-center" onSubmit={handleSubmit} >
        <div className="flex justify-end">
       <div className="flex content-end p-5 gap-4">
            <button
              className="cursor-pointer rounded px-3 shadow bg-blue-900 text-white"
              type="submit"
              
            >
              update
            </button>
            <button className="cursor-pointer rounded px-3 shadow bg-red-500 text-white">
              Cancel
            </button>
          </div>
          </div>
          <div className="w-full mt-4">
            
              <div className="table w-full">
                
                  <div className="table-row-group">
                    <div className="flex flex-col content-center">
                     
                      <div className="table-cell p-2">
                        <div>User name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateUser.user_name}
                          onChange={handleUserChange}
                          name="user_name"
                        />
                      </div>
                      <div className="table-cell p-2">
                        <div>First Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateUser.first_name}
                          onChange={handleUserChange}
                          name="first_name"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Last Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateUser.last_name}
                          onChange={handleUserChange}
                          name="last_name"
                        />
                      </div>
                    
                      <div className="table-cell   p-2">
                        <div>Email </div>

                        <input
                          type="email"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateUser.email_c}
                          onChange={handleUserChange}
                          name="email_c"
                        />
                      </div>
                     
                      <div className="table-cell   p-2">
                        <div>Phone Number </div>

                        <input
                          type="tel"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={updateUser.phone_mobile}
                          onChange={handleUserChange}
                          name="phone_mobile"
                          pattern="[0-9]{10}"
                        />
                      </div>
                      
                    </div>
                   
                  </div>
               
              </div>
              </div>
              </form>
           
              </div>
       
         <MDBox>
           
         </MDBox>
       </MDBox>
       
     </DashboardLayout>
     </>
  )
}

export default updateUser