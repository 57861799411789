import React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import category from "layouts/category";

const addUser = () => {
  const [job, setjob] = useState({
    role: "",
    company: "",
    location: "",
    type: "",
    category: "",
    skills: "",
    closeDate: "",
  });
  const sessionId = useSelector((state) => state.session.sessionId);
  console.log(sessionId);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setjob((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = "https://seltentechnologiesdemo.com/moona-backend/service/v4_1/rest.php";
    const method = "set_entry";
    const input_type = "JSON";
    const response_type = "JSON";

    const rest_data = JSON.stringify({
      session: sessionId,
      module_name: "job_JOB_",
      name_value_list: [
        { name: "name", value: job.name },
        { name: "company_name_c", value: job.company },
        { name: "location_c", value: job.location },
        { name: "category_c", value: job.category },
        { name: "required_skills_c", value: job.skills },
        { name: "closedate_c", value: job.closeDate },
        // { name: "phone_mobile", value: user.phone_mobile },
      ],
    });

    const fullUrl = `${apiUrl}?method=${method}&input_type=${input_type}&response_type=${response_type}&rest_data=${encodeURIComponent(
      rest_data
    )}`;

    try {
      const response = await axios.post(fullUrl);
      console.log("API Response:", response.data);
      navigate("/jobListing");
    } catch (error) {
      if (error.response) {
        console.error("An error occurred during login:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <div className="flex flex-col">
            <form className="w-full flex flex-col justify-center" onSubmit={handleSubmit}>
              <div className="flex justify-end">
                <div className="flex content-end p-5 gap-4">
                  <button
                    className="cursor-pointer rounded px-3 shadow bg-blue-900 text-white"
                    type="submit"
                  >
                    save
                  </button>
                  <button className="cursor-pointer rounded px-3 shadow bg-red-500 text-white">
                    Cancel
                  </button>
                </div>
              </div>
              <div className="w-full mt-4">
                <div className="table w-full">
                  <div className="table-row-group">
                    <div className="flex flex-col content-center">
                      <div className="table-cell p-2">
                        <div>Role </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={job.name}
                          onChange={handleChange}
                          name="name"
                        />
                      </div>
                      <div className="table-cell    p-2">
                        <div>Company Name </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={job.company}
                          onChange={handleChange}
                          name="company"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Location </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={job.location}
                          onChange={handleChange}
                          name="location"
                        />
                      </div>

                      

                      <div className="table-cell   p-2">
                        <div>Category </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={job.category}
                          onChange={handleChange}
                          name="category"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Skill </div>

                        <input
                          type="text"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={job.skills}
                          onChange={handleChange}
                          name="skills"
                        />
                      </div>
                      <div className="table-cell   p-2">
                        <div>Close Date </div>

                        <input
                          type="date"
                          className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          value={job.closeDate}
                          onChange={handleChange}
                          name="closeDate"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <MDBox></MDBox>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default addUser;
